import { useCallback, useMemo, useState } from 'react';
import { captureException } from '@sentry/react';
import { useTranslation } from 'next-i18next';
import type { UseGetUserAudioDevicesProps } from './useGetUserAudioDevices.types';
import { UserAudioDevices } from '../../../../types/MediaDevice';

export const useGetUserAudioDevices = ({ handleError }: UseGetUserAudioDevicesProps) => {
  const { t } = useTranslation('error');
  const [userAudioDevices, setUserAudioDevices] = useState<UserAudioDevices>({
    currentAudioInDevices: [],
    isAudioAllow: null,
  });
  const [isRequestAudioDeviceInProgress, setIsRequestInProgress] = useState(false);
  const constraints: MediaStreamConstraints = useMemo(() => ({ audio: true }), []);

  const getUserAudioDevices = useCallback(() => {
    (async () => {
      if (isRequestAudioDeviceInProgress) return;
      setIsRequestInProgress(true);
      await navigator.mediaDevices
        ?.getUserMedia(constraints)
        .then((stream) => {
          // NOTE: ストリームリストのキャプチャ状態を開放する
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((e) => {
          // HACK: （暫定対応）デバイスが使用されている場合は'NotAllowedError'ではないので、throwされない
          if ((e as Error).name === 'NotAllowedError') {
            setUserAudioDevices({
              ...userAudioDevices,
              isAudioAllow: false,
            });

            if (handleError) {
              handleError(t('deviceSetting.micAccess'));
            }

            throw new Error('UserMedia mic is not allowed');
          }
        });

      const audioInDevices: MediaDeviceInfo[] = await navigator.mediaDevices
        .enumerateDevices()
        .then((data) => data.filter((device) => device.kind === 'audioinput'));

      if (audioInDevices.length === 0) {
        if (handleError) {
          handleError(t('deviceSetting.noMic'));
        }
      }
      // HACK: （暫定対応）デバイスが使用されている場合もここを通る。Trackを作る時にエラーとなる
      setUserAudioDevices({
        currentAudioInDevices: audioInDevices,
        isAudioAllow: true,
      });
    })()
      .catch(captureException)
      .finally(() => setIsRequestInProgress(false));
  }, [t, constraints, isRequestAudioDeviceInProgress, userAudioDevices, handleError]);

  return { isRequestAudioDeviceInProgress, userAudioDevices, getUserAudioDevices };
};
