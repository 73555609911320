import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AgeVerificationBarcodeState = {
  isOpenDialog: boolean;
  barcodeValue?: string;
};

const initialState: AgeVerificationBarcodeState = {
  isOpenDialog: false,
  barcodeValue: undefined,
};

export const ageVerificationBarcodeSlice = createSlice({
  name: 'ageVerificationBarcode',
  initialState,
  reducers: {
    openDialogAgeVerificationBarcode: (state, action: PayloadAction<string>) => {
      state.isOpenDialog = true;
      state.barcodeValue = action.payload;
    },
    closeDialogAgeVerificationBarcode: (state) => {
      state.isOpenDialog = false;
      state.barcodeValue = undefined;
    },
  },
});

export const { openDialogAgeVerificationBarcode, closeDialogAgeVerificationBarcode } =
  ageVerificationBarcodeSlice.actions;

export default ageVerificationBarcodeSlice.reducer;
