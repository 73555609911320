import { useTranslation } from 'next-i18next';
import styles from './PageError.module.scss';
import { ErrorContent } from '../../atoms/ErrorContent';

export const PageError = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <div className={styles.container}>
      <div className={styles.pageError}>
        <ErrorContent status="error">
          <p>{t('errorPage.p1')}</p>
          <p>{t('errorPage.p2')}</p>
        </ErrorContent>
      </div>
    </div>
  );
};
