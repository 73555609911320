import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useTranslation } from 'next-i18next';
import { RootState } from '../../../store';
import { useBodyElement } from '../../../hooks/useBodyElement';
import type { OnLogin } from './DialogLogin.types';
import { resetAuthErrorStatus } from '../../../store/features/authFirebase';
import { useFirebaseErrorMessage } from '../../../errors/ErrorLogin';

export type TFormInput = {
  email: string;
  password: string;
};

export const useDialogLogin = (onLogin: OnLogin, resStatusCode?: number) => {
  const { root } = useBodyElement();
  const isOpen = useSelector((state: RootState) => state.dialog.isOpenLogin);
  const dispatch = useDispatch();
  const { t } = useTranslation('error');
  const { errorMessage } = useFirebaseErrorMessage();

  const schema = object({
    email: string().required(t('login.emptyEmail')).email(t('login.invalidEmail')),
    password: string().required(t('login.emptyPassword')).min(6, t('login.passwordLength')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<TFormInput> = (data) => {
    const { email, password } = data;
    onLogin(email, password);
  };
  const handleAuthErrorStatus = useCallback(() => {
    dispatch(resetAuthErrorStatus());
  }, [dispatch]);

  // FIXME: inputタグのエラーメッセージが１つしか表示されないので、複数表示できるように修正する
  const loginErrorMessage: string | undefined = useMemo(() => {
    if (errors.email?.message) {
      return errors.email.message;
    }
    if (errors.password?.message) {
      return errors.password.message;
    }
    if (resStatusCode && errorMessage[resStatusCode]) return `${resStatusCode}: ${errorMessage[resStatusCode]}`;
    if (resStatusCode && resStatusCode.toString().startsWith('4')) return `${resStatusCode}: ${t('login.unexpected')}`;
    return undefined;
  }, [errors.email, errors.password, resStatusCode, errorMessage, t]);

  return {
    root,
    isOpen,
    register,
    handleSubmit,
    onSubmit,
    errors,
    loginErrorMessage,
    handleAuthErrorStatus,
  };
};
