import { ScenarioData, ScenarioInstanceType } from './types.generated';

export const ScenarioInstanceTypeCustomEnum = {
  ...ScenarioInstanceType,
  CloseScenario: 'close_scenario',
  InitialScenario: 'initial_scenario',
} as const;

export const ScenarioStatusEnum = {
  INITIAL: 'initial',
  IN_PROGRESS: 'inProgress',
  CALLING: 'calling',
  ERROR: 'error',
} as const;

export type ScenarioInstanceTypeCustom =
  (typeof ScenarioInstanceTypeCustomEnum)[keyof typeof ScenarioInstanceTypeCustomEnum];

export type ScenarioStatus = (typeof ScenarioStatusEnum)[keyof typeof ScenarioStatusEnum];

export type ScenarioDataCustom = Pick<ScenarioData, 'nodeId' | 'params'> & {
  instanceType: ScenarioInstanceTypeCustom;
  talkMode?: TalkMode;
};

export type UnHandledInstancesState = {
  unHandledInstances: ScenarioDataCustom[] | undefined;
  scenarioStatus: ScenarioStatus;
  currentActiveOperators?: number;
};

export const ButtonType = {
  NEXT: 'next',
  PREV: 'prev',
  CLOSE: 'close',
  CHOICE: 'choice',
  CALL: 'call',
} as const;

export type SelectButtonType = (typeof ButtonType)[keyof typeof ButtonType];

export type SelectButtonItem = {
  type: SelectButtonType;
  text: string;
};

export type ChatGPTRole = 'user' | 'assistant';

export type ChatGPTMessage = {
  role: ChatGPTRole;
  content: string;
};

export const TalkModeEnum = {
  BUTTON: 'button',
  VOICE_INPUT: 'voiceInput',
  TENKEY_INPUT: 'tenkeyInput',
  MESSAGE: 'message',
} as const;

export type TalkMode = (typeof TalkModeEnum)[keyof typeof TalkModeEnum];

export type AddChatLog = (chatLog: ChatGPTMessage) => void;

export const ButtonVariantEnum = {
  DEFAULT: 'default',
  RED: 'red',
} as const;

export type ButtonVariant = (typeof ButtonVariantEnum)[keyof typeof ButtonVariantEnum];
