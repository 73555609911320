import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectFragmentFragment, RoomFragmentFragment } from '../../../graphql/fragment.generated';

// FIXME: roomKeyのことをidと呼ぶので良いだろうか
export type CustomerState = {
  firebaseUuid?: string;
  name?: string;
  selectedProject?: ProjectRedux;
  selectedRoomId?: string; // NOTE: idはroomKeyのこと（ADDに準じる）
  conferenceRoomId?: string; // NOTE: idはroomKeyのこと（ADDに準じる）
  selectedRoom?: RoomRedux;
  realtimeDatabasePath?: string | null;
  introductionMessage?: string;
};

// TODO: UUID対応 id の削除 (projectId, sentry 関連が先)
// NOTE: fragmentを使って`query customer`と`mutation signInWithWebBasedRoom`の型を合している
// TODO: CoT連携 (pages/chat-support) がなくなったので上記は不要で, types/Room に変更できるか検討
export type ProjectRedux = ProjectFragmentFragment | null | undefined;
export type RoomRedux = Pick<
  RoomFragmentFragment,
  | 'uuid'
  | 'projectId'
  | 'projectUuid'
  | 'name'
  | 'key'
  | 'documentListenPath'
  | 'type'
  | 'faceDetectionAlertDuration'
  | 'assignedScenarios'
>;

const initialState: CustomerState = {};

export const customerAnonymousAuthSlice = createSlice({
  name: 'customerAnonymousAuth',
  initialState,
  reducers: {
    resetCustomerAnonymousAuthState: () => initialState,
    updateCustomerAnonymousAuthState: (state, action: PayloadAction<CustomerState | undefined>) => ({
      ...state,
      ...action.payload,
    }),
    updateSelectedRoomAnonymousAuth: (state, action: PayloadAction<RoomRedux | undefined>) => {
      state.selectedRoom = action.payload;
    },
    updateSelectedProjectAnonymousAuth: (state, action: PayloadAction<ProjectRedux | undefined>) => {
      state.selectedProject = action.payload;
    },
    updateSelectRoomIdAnonymousAuth: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRoomId = action.payload;
    },
    updateConferenceRoomIdAnonymousAuth: (state, action: PayloadAction<string | undefined>) => {
      state.conferenceRoomId = action.payload;
    },
  },
});

export const {
  resetCustomerAnonymousAuthState,
  updateCustomerAnonymousAuthState,
  updateSelectedRoomAnonymousAuth,
  updateSelectedProjectAnonymousAuth,
  updateSelectRoomIdAnonymousAuth,
  updateConferenceRoomIdAnonymousAuth,
} = customerAnonymousAuthSlice.actions;

export default customerAnonymousAuthSlice.reducer;
