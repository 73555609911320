import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

// 自律応対ダイアログのフリーズ状態
export const isScenarioDialogFreezeSlice = createSlice({
  name: 'isScenarioDialogFreeze',
  initialState,
  reducers: {
    updateIsScenarioDialogFreeze: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { updateIsScenarioDialogFreeze } = isScenarioDialogFreezeSlice.actions;

export default isScenarioDialogFreezeSlice.reducer;
