import { useTranslation } from 'next-i18next';
import styles from './DialogLoginBody.module.scss';
import { InputLabelWithTextForm } from '../InputLabelWithTextForm/InputLabelWithTextForm.component';
import { Mail, VpnKey } from '../../../assets/icons';
import { DialogLoginBodyProps } from './DialogLoginBody.types';
import { ErrorMessage } from '../../atoms/ErrorMessage';

export const DialogLoginBody = ({
  errors,
  passwordRegister,
  emailRegister,
  loginErrorMessage,
  handleAuthErrorStatus,
}: DialogLoginBodyProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <InputLabelWithTextForm
        register={emailRegister}
        textFieldType="text"
        icon={<Mail />}
        label={t('login.form.email')}
        placeholder={t('login.form.emailPlaceholder')}
        errored={!!errors?.email}
        onFocus={handleAuthErrorStatus}
      />
      <InputLabelWithTextForm
        register={passwordRegister}
        textFieldType="password"
        icon={<VpnKey />}
        label={t('login.form.password')}
        placeholder={t('login.form.passwordPlaceholder')}
        errored={!!errors?.password}
        onFocus={handleAuthErrorStatus}
      />
      {loginErrorMessage && <ErrorMessage message={loginErrorMessage} />}
    </div>
  );
};
