import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type URLType = 'image' | 'others';

export type AiResponseURL = {
  url?: string;
  type?: URLType;
  isLink?: boolean;
};

const initialState: AiResponseURL[] = [];

export const aiResponseUrlSlice = createSlice({
  name: 'aiResponseUrls',
  initialState,
  reducers: {
    updateUrls: (_, action: PayloadAction<AiResponseURL[]>) => action.payload,
    deleteFirstUrl: (state) => state.filter((_, index) => index !== 0),
    resetUrls: () => initialState,
  },
});

export const { updateUrls, deleteFirstUrl, resetUrls } = aiResponseUrlSlice.actions;

export default aiResponseUrlSlice.reducer;
