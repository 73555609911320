import { AuthErrorCodes } from 'firebase/auth';
import { useTranslation } from 'next-i18next';

type TErrorMessage = {
  [key: number]: string;
};

export const useFirebaseErrorMessage = () => {
  const { t } = useTranslation('error');
  const errorMessage: TErrorMessage = {
    400: t('login.400'),
    401: t('login.401'),
    403: t('login.403'),
    404: t('login.404'),
    407: t('login.407'),
    408: t('login.408'),
    415: t('login.415'),
    500: t('login.500'),
    503: t('login.503'),
  };

  return {
    errorMessage,
  };
};

export const authErrorCodes = [
  'auth/invalid-login-credentials', // 無効なログイン
  AuthErrorCodes.INVALID_AUTH, // 無効な認証
  AuthErrorCodes.INVALID_EMAIL, // 無効なemail
  AuthErrorCodes.INVALID_PASSWORD, // 無効なパスワード
  AuthErrorCodes.USER_DISABLED, // ユーザーが無効化されています
  AuthErrorCodes.USER_DELETED, // ユーザーが削除されている
  AuthErrorCodes.USER_MISMATCH, // ユーザーが一致しません
  AuthErrorCodes.INVALID_CUSTOM_TOKEN, // カスタムトークンが無効です
  AuthErrorCodes.INVALID_IDP_RESPONSE, // 資格情報が無効です
  AuthErrorCodes.INVALID_OOB_CODE, // アクションコードが無効です
  AuthErrorCodes.INVALID_CODE, // verificationコードが無効です
  AuthErrorCodes.INVALID_SESSION_INFO, // verificationIDが無効です
  AuthErrorCodes.INVALID_MFA_SESSION, // MFAセッションが無効です
  AuthErrorCodes.MFA_REQUIRED, // MFAが必要です
  AuthErrorCodes.USER_CANCELLED, // ユーザーがキャンセルしました
  AuthErrorCodes.USER_SIGNED_OUT, // ユーザーがサインアウトしました
  AuthErrorCodes.TOKEN_EXPIRED, // トークンが期限切れです
  AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER, // 多くのリクエストが送信されました
];
