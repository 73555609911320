import ArrowDropDown from './MaterialDesignIcons/Filled/arrow_drop_down.svg';
import ArrowBack from './MaterialDesignIcons/Filled/arrow_back.svg';
import Check from './MaterialDesignIcons/Filled/check.svg';
import Gear from './MaterialDesignIcons/Filled/gear.svg';
import MoreHoriz from './MaterialDesignIcons/Filled/more_horiz.svg';
import VideocamOff from './MaterialDesignIcons/Filled/videocam_off.svg';
import Close from './MaterialDesignIcons/Filled/close.svg';
import VideoCam from './MaterialDesignIcons/Filled/videocam.svg';
import Mic from './MaterialDesignIcons/Filled/mic.svg';
import CallEnd from './MaterialDesignIcons/Filled/call_end.svg';
import Mail from './MaterialDesignIcons/Filled/mail.svg';
import VpnKey from './MaterialDesignIcons/Filled/vpn_key.svg';
import Warning from './MaterialDesignIcons/Filled/warning.svg';
import RemoveRedEye from './MaterialDesignIcons/Filled/remove_red_eye.svg';
import VisibilityOff from './MaterialDesignIcons/Filled/visibility_off.svg';
import MeetingRoom from './MaterialDesignIcons/Filled/meeting_room.svg';
import Person from './MaterialDesignIcons/Filled/person.svg';
import Setting from './MaterialDesignIcons/Filled/settings.svg';
import Send from './MaterialDesignIcons/Filled/send.svg';
import AccountBox from './MaterialDesignIcons/Filled/account_box.svg';
import FlipCameraIos from './MaterialDesignIcons/Filled/flip_camera_ios.svg';
import Logout from './MaterialDesignIcons/Filled/logout.svg';
import Chat from './MaterialDesignIcons/Filled/chat.svg';
import Minus from './MaterialDesignIcons/Filled/minus.svg';
import Info from './MaterialDesignIcons/Filled/info.svg';
import Call from './MaterialDesignIcons/Filled/call.svg';
import GoogleAssistant from './MaterialDesignIcons/google_assistant.svg';
import MicOff from './MaterialDesignIcons/Filled/mic_off.svg';
import FirstPage from './MaterialDesignIcons/Filled/first_page.svg';

export {
  ArrowDropDown,
  ArrowBack,
  Check,
  Chat,
  Gear,
  MoreHoriz,
  Close,
  VideoCam,
  Mic,
  VideocamOff,
  CallEnd,
  Mail,
  VpnKey,
  Warning,
  RemoveRedEye,
  VisibilityOff,
  MeetingRoom,
  Person,
  Setting,
  Send,
  AccountBox,
  FlipCameraIos,
  Logout,
  Minus,
  Info,
  Call,
  GoogleAssistant,
  MicOff,
  FirstPage,
};
