import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ScenarioProgressRate = {
  rate: string | undefined;
};

const initialState: ScenarioProgressRate = {
  rate: undefined,
};

export const scenarioProgressRateSlice = createSlice({
  name: 'scenarioProgressRate',
  initialState,
  reducers: {
    updateScenarioProgressRate: (state, action: PayloadAction<string>) => {
      state.rate = action.payload;
    },
  },
});

export const { updateScenarioProgressRate } = scenarioProgressRateSlice.actions;

export default scenarioProgressRateSlice.reducer;
