import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScenarioLanguageCode } from '../../../types';

export type SelectLanguageState = {
  selectedLanguage: ScenarioLanguageCode;
  lastSelectedLanguage: ScenarioLanguageCode;
};

const initialState: SelectLanguageState = {
  selectedLanguage: ScenarioLanguageCode.Ja,
  lastSelectedLanguage: ScenarioLanguageCode.Ja,
};

export const selectLanguageSlice = createSlice({
  name: 'selectLanguage',
  initialState,
  reducers: {
    updateSelectedLanguage: (state, action: PayloadAction<ScenarioLanguageCode>) => {
      state.selectedLanguage = action.payload;
      // 最後に選択された言語を更新
      if (action.payload !== null) state.lastSelectedLanguage = action.payload;
    },
  },
});

export const { updateSelectedLanguage } = selectLanguageSlice.actions;

export default selectLanguageSlice.reducer;
